import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import video_recording from "../../Images/video_recording.png";
import hand_outer from "../../Images/hand_outer.png";
import mic from "../../Images/mic.gif";
import arrow_right from "../../Images/arrow_right.png";
import ctre45 from "../../Images/ctre45.png";
import Direction from "./components/direction";
import { isMobile } from "react-device-detect";
import {
  getCreateQuestion,
  saveCreateQuestion,
  skipCreateQuestion,
  getQuestionById,
  skipCurrentModule,
  getCreateRestoreSession,
  scanQuestions,
  verifyUserSubscription,
} from "../../redux/actions";
import connection from "../../config/connection";
import chat_tak from "../../Images/chat_tak.png";
import blog1 from "../../Images/blog1.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import RecordRTC, { invokeSaveAsDialog } from "recordrtc";
import getBlobDuration from "get-blob-duration";
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import ringtone from "../../Sounds/new-ringtone.mp3";
import Loader from "react-loader";
import { browserName, browserVersion } from "react-device-detect";
import HeaderComponent from "../../components/Header";
import { stopwatch } from "durations";
import SkipModuleButton from "./components/skipModuleButton";
import SkipQuestionButton from "./components/skipQuestionButton";


//import videojs from "video.js";
//import "video.js/dist/video-js.css";
//import 'videojs-record/dist/css/videojs.record.css';
//import Record from 'videojs-record/dist/videojs.record.js';
import "webrtc-adapter";
import AudioAnalyser from "./AudioAnalyser";
import videojs from "video.js";

import $ from 'jquery';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.js';

const watch = stopwatch();
var interval = 0;

const videoIOSType = "video/mp4";
const videoWebType = "video/webm";
const audioType = "audio/*";
const iosCheck =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const details = navigator.userAgent;
const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice =
  regexp.test(details) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
const checkSafari =
  /Safari|safari/.test(navigator.userAgent) && !window.MSStream;

const videoJsOptions7 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/259abd55-d5eb-48a7-bb72-38d782f825b5/hls/Legacy Eternaview Demo January 8th 2024Todd Revision (1).m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

class CreateEternaview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirectionOpen: false,
      isVideoRecoding: false,
      recording: false,
      recordingAfter: false,
      videos: [],
      data: {},
      nextpage: "",
      recordedVideo: {},
      recordedAudio: {},
      category: "",
      subcategory: "",
      numberRecordings: "",
      stream: {},
      submitFlag: false,
      completedata: {},
      isSubmitted: false,
      videoUrl: "",
      audioUrl: "",
      duration: "",
      seconds: 4,
      typecreate: "",
      timer: 0,
      isActive: false,
      isPaused: false,
      loaded: true,
      type: "",
      transition: "",
      moduleQuestions: [],
      reloadFlag: "",
      total_time: false,
      grace_total_time: false,
      total_time_left: false,
      detectAudio: null,
      plan_id: "",
      viewId: "",
      screen_mode: "",
      record_click: "submit",
      moveAheadModal: false,
      hasMicrophone: false,
      hasWebcam: false,
      videoOptions: {
        video: {
          width: { min: 320, ideal: 1920, max: 1920 },
          height: { min: 180, ideal: 1080, max: 1080 },
          aspectRatio: 1.7777777778,
          facingMode: "user",
          // width: { min: 320, ideal: 580, max: 1920 },
          // height: { min: 240, ideal: 420, max: 1080 },
          // aspectRatio: 1.777777778,
        },
        audio: true,
      },
      videoFacing: "user",
      isProcessing: false,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      recordingMiliSeconds: 0,
      showSkip: true,
      isRetakeSec: false, // used boolean  isRetakeSec to dynamically update its value based on sect 60-120 on video retake
      baseName:"home",
    };
    this.ManageOrientation = this.ManageOrientation.bind(this);
    this.countRef = React.createRef(null);
    this.myInterval = React.createRef(null);
  }
  startRecordingTimer() {
    let interval = 0;
    setInterval(() => {
      // console.log((watch.duration().hours().toString()).split(".")[0]+":"+(watch.duration().minutes().toString()).split(".")[0]+":"+(((watch.duration().seconds()-interval)).toString()).split(".")[0]);
      this.state.recordingMiliSeconds = watch
        .duration()
        .millis()
        .toString()
        .split(".")[0];
      this.state.recordingHours = watch
        .duration()
        .hours()
        .toString()
        .split(".")[0];
      this.state.recordingMinutes = watch
        .duration()
        .minutes()
        .toString()
        .split(".")[0];
      this.state.recordingSeconds = (watch.duration().seconds() - interval)
        .toString()
        .split(".")[0];
      console.log(
        "second is " +
          watch.duration().seconds().toString().split(".")[0] +
          "interval is " +
          interval
      );
      if (
        watch.duration().seconds().toString().split(".")[0] - interval ==
        59
      ) {
        interval = interval + 60;
      }

      // console.log(interval);
    }, 1000);
  }
  format(num) {
    return (num + "").length === 1 ? "0" + num : num + "";
  }

  saveToServer = (event) => {
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
      this.state.recordingMiliSeconds = 0;
      this.state.recordingHours = 0;
      this.state.recordingMinutes = 0;
      this.state.recordingSeconds = 0;
    }
    if (this.state.numberRecordings == "") {
      toast("Please record your answer to submit successfully.", {
        position: "top-right",
        autoClose: 3000,
        type: "warning",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }

    if (this.state.duration != "") {
      this.setState({ loaded: false });
      this.setState({ submitFlag: true });
      let formData = new FormData();
      formData.append("video", this.state.recordedVideo);
      formData.append("question_id", this.state.data.id);
      formData.append("language", this.state.data.language.id);
      formData.append("duration", this.state.duration);
      formData.append("size", this.state.recordedVideo.size);
      formData.append("view_id", this.state.viewId);
      formData.append("screen_mode", this.state.screen_mode);
      if (this.state.typecreate == 0) {
        formData.append("type", "video");
      } else {
        formData.append("type", "audio");
      }

      this.props.saveCreateQuestion(
        formData,
        this.props.auth.loginUserToken,
        (res) => {
          this.setState({ videoUrl: "" });
          if (res.is_active == false) {
            localStorage.clear();
            // this.props.history.replace("/");
            window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
          } else {
            this.setState({ loaded: true });
            this.setState({ seconds: 4 });
            this.setState({ timer: 0, duration: "" });
            if (res.status == 200) {
              toast("Answer saved.", {
                position: "top-right",
                autoClose: 3000,
                type: "success",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });

              if (this.state.completedata.next == null) {
                if (
                  this.state.completedata.current_module ==
                  this.state.completedata.total_sub_module
                ) {
                  this.setState({ transition: "yes" });
                  this.getQuestion(
                    this.state.category,
                    this.state.subcategory,
                    "yes",
                    this.state.type,
                    this.state.viewId
                  );
                } else {
                  this.getNextModule();
                }
              } else {
                if (this.state.transition == "yes") {
                  this.getQuestion(
                    this.state.category,
                    this.state.subcategory,
                    "",
                    this.state.type,
                    this.state.viewId
                  );
                } else {
                  this.getQuestion(
                    this.state.category,
                    this.state.subcategory,
                    "",
                    this.state.type,
                    this.state.viewId
                  );
                }
              }
              this.setState({ submitFlag: false, isSubmitted: true });
            } else {
              toast(res.error, {
                position: "top-right",
                autoClose: 3000,
                type: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
            }
          }
        }
      );
    } else {
      toast(
        "Please wait until processing is complete before trying to submit.",
        {
          position: "top-right",
          autoClose: 3000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
    }

    event.preventDefault();
  };
  ManageOrientation = () => {
    var mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    if (mql.matches) {
      // Portrait orientation
      // alert("You are in Portrait mode load");
      this.setState({ screen_mode: "portrait" });
    } else {
      // Landscape orientation
      // alert("You are in landscape mode load");
      this.setState({ screen_mode: "landscape" });
    }

    const callBack = (m) => {
      if (m.matches) {
        // Changed to portrait
        // alert("You are in Portrait mode change");
        this.setState({ screen_mode: "portrait" });
      } else {
        // Changed to landscape
        // alert("You are in landscape mode change");
        this.setState({ screen_mode: "landscape" });
      }
    };
    // Add a media query change listener
    mql.addListener(callBack);
  };

  async componentDidMount() {

    const { location } = this.props;
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    if(splitLocation[1]=='dating')
    {
      this.setState({ baseName:'dating' });
    }
    else
    {
      this.setState({ baseName:'home' });
    }



    this.ManageOrientation();
    this.checkCookie();
    this.player7 = videojs(
      this.video7Node,
      videoJsOptions7,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player7.on("deviceReady", () => {
      console.log("device is ready!");
    });

    /*  try{

        


        if (window.matchMedia("(orientation: portrait)").matches) {
            this.setState({ screen_mode: 'portrait' });
          
        //   document.getElementById('screen_mode').value="portrait";
         //  alert("You are in portrait mode load");
         }
         
         if (window.matchMedia("(orientation: landscape)").matches) {
           // alert("You are in landscape mode load");
            this.setState({ screen_mode: 'landscape' });
          // $("#screen_mode").val('landscape');
         //  document.getElementById('screen_mode').value="landscape";
         }

         var self = this;          // Store `this` component outside the callback

        window.screen.orientation.onchange = function(e) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                self.setState({ screen_mode: 'portrait' });
               
               // document.getElementById('screen_mode').value="portrait";
             //   alert("You are in portrait mode change");
              }
              
              if (window.matchMedia("(orientation: landscape)").matches) {
               //  alert("You are in landscape mode change");
                 self.setState({ screen_mode: 'landscape' });
               // $("#screen_mode").val('landscape');
               //// document.getElementById('screen_mode').value="landscape";
              }
            }


        }

     catch (error) {
        alert (error.message);
      } */

    // var mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    // if (mql.matches) {
    //   // Portrait orientation
    //   // alert("You are in Portrait mode load");
    //   this.setState({ screen_mode: "portrait" });
    // } else {
    //   // Landscape orientation
    //   // alert("You are in landscape mode load");
    //   this.setState({ screen_mode: "landscape" });
    // }

    // Add a media query change listener
    // mql.addListener(function (m) {
    //   if (m.matches) {
    //     // Changed to portrait
    //     // alert("You are in Portrait mode change");
    //     this.setState({ screen_mode: "portrait" });
    //   } else {
    //     // Changed to landscape
    //     // alert("You are in landscape mode change");
    //     this.setState({ screen_mode: "landscape" });
    //   }
    // });

    if (this.props.auth.loginUserToken == "") {
      localStorage.clear();
      // this.props.history.replace("/");
      window.location.href = connection.homePage
    }

    let split = this.props.match.params.id.split("+");
    let viewId = "";

    let typeflag = "",
      typecreate = "";
    if (split[2] == 0) {
      typeflag = "video";
      typecreate = "video_audio";
    } else {
      typeflag = "audio";
      typecreate = "audio";
    }

    if (split[3]) {
      viewId = split[3];
    }

    this.setState({ type: typeflag });

    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
      if (res.data && res.data.length > 0) {
        let typecreate = split[2] == 0 ? "video_audio" : "audio";

        res.data.map((mod, ind) => {
          // if (split[0] == mod.subscribe_plan__category__id) {
          if (mod.views == viewId) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate
              ) {
                this.setState({ plan_id: mod.id, viewId: mod.views });
                viewId = mod.views;
                return;
              }
            } else {
             /*  alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              ); */
              $.alert({
                title: 'Alert!',
                content: "Apologies! but it seems like your subscription plan's payment is either inactive or pending",
              });
              // this.props.history.replace("/");
              window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
            }
          }
        });

        if (viewId != "") {
          this.props.getCreateRestoreSession(
            split[0],
            split[1],
            typeflag,
            viewId,
            this.props.auth.loginUserToken,
            (response) => {
              if (response.id != "" && response.id != undefined) {
                this.getQuestion(
                  split[0],
                  split[1],
                  response.id,
                  typeflag,
                  viewId
                );
              } else if (response.module_id) {
                this.getQuestion(
                  split[0],
                  response.module_id,
                  response.id,
                  typeflag,
                  viewId
                );
              } else if (
                response.transition &&
                response.transition.toLowerCase() == "yes"
              ) {
                this.getQuestion(split[0], split[1], "", typeflag, viewId);
              } else {
                this.getQuestion(split[0], split[1], "", typeflag, viewId);
              }
            }
          );
        }

        if (res.data.updated_details.total_time != 0) {
          // let remaining_time = (res.data.subscribe_plan__total_time * 3600) - res.data.updated_details.total_time;
          //  remaining_time = Math.ceil(remaining_time / 3600);
          let remaining_time = res.data.updated_details.total_time;
          if (remaining_time <= 1) {
            this.setState({ total_time: true });
          }
        } else if (
          res.data.updated_details.total_time == 0 &&
          res.data.updated_details.grace_time > 0
        ) {
          //  let remaining_time = (res.data.subscribe_plan__grace_total_time * 3600) - res.data.updated_details.grace_time;
          //   remaining_time = Math.ceil(remaining_time / 3600);
          let remaining_time = res.data.updated_details.grace_time;
          if (remaining_time <= 1) {
            this.setState({ grace_total_time: true });
          }
        } else if (
          res.data.updated_details.total_time == 0 &&
          res.data.updated_details.grace_time == 0
        ) {
          this.setState({ total_time_left: true });
        }
      }
    });

    this.setState({ typecreate: split[2] });

    if (split[2] == 0 || split[2] == "0") {
      this.video.src = this.video.srcObject = null;
      this.video.muted = false;
      this.video.volume = 1;
    } else if (split[2] == 1 || split[2] == "1") {
      if (this.audio) {
        this.audio.src = this.audio.srcObject = null;
        this.audio.muted = true;
        this.audio.volume = 0;
      }
    }

    this.props.scanQuestions(
      {},
      `&question_scan=True`,
      this.props.auth.loginUserToken,
      (res) => {
        let con = res.data,
          questions = [],
          match = res.transition_name;

        if (res.data.length > 0) {
          con.map((data, index) => {
            if (data.id == split[0]) {
              data.modules.map((mod, ind) => {
                if (
                  mod.subcategory_name !=
                    "Interaction Facilitation Part 1 (required)" &&
                  mod.subcategory_name != "Interaction Facilitation Part 2" &&
                  mod.subcategory_name != "Pleasantries" &&
                  mod.subcategory_name !=
                    "Interaction Facilitation & Pleasantries (required)"
                ) {
                  questions.push(mod);
                }
              });
            }
          });
          this.setState({ moduleQuestions: questions });
        }
      }
    );
  }

  getQuestion(cat, subcat, restore, type, viewId) {
    this.setState({
      showSkip: false,
      duration: 0,
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      numberRecordings: "",
      current_time_recording: "",
      record_click: "nextQuestion",
    });

    this.setState({ category: cat, subcategory: subcat, type: type });

    let url = `category=${cat}&sub_category=${subcat}&type=${type}&view_id=${viewId}`;
    if (restore != "" && restore != "yes") {
      url = `category=${cat}&sub_category=${subcat}&restore_session=${restore}&type=${type}&view_id=${viewId}`;
    } else if (restore == "yes") {
      url = `category=${cat}&sub_category=${subcat}&transition=yes&type=${type}&view_id=${viewId}`;
    } else {
      url = `category=${cat}&sub_category=${subcat}&type=${type}&view_id=${viewId}`;
    }

    this.props.getCreateQuestion(url, this.props.auth.loginUserToken, (res) => {
      // console.log('+++++++res++++++++++++++');
      //  console.log(res.data[0].question);
      //alert(res.data[0].question);

      if (res.data && res.data.length > 0) {
        this.setState({
          showSkip: true,
          data: res.data[0],
          nextpage: res.current_page,
          completedata: res,
        });
        this.setState({
          recording: false,
          numberRecordings: "",
          isSubmitted: false,
        });
      } else if (res.data && res.data.length == 0) {
        console.log(res, "<- res");
        // if(this.state.reloadFlag != '') {
        //     this.props.history.push(`/create/congratulations/${cat}+${subcat}+${type}+${this.state.reloadFlag}`);
        // } else {
        this.props.history.push(
          `/${this.state.baseName}/create/congratulations/${cat}+${subcat}+${type}+${this.state.plan_id}+${this.state.viewId}`
        );
        //}
      }
    });
  }

  videoOptionsChange = (e) => {
    // alert(e.target.value);
    let videoOptions = {
      video: {
        width: { min: 320, ideal: 1920, max: 1920 },
        height: { min: 180, ideal: 1080, max: 1080 },
        aspectRatio: 1.7777777778,
        facingMode: e.target.value,
        // width: { min: 320, ideal: 580, max: 1920 },
        // height: { min: 240, ideal: 420, max: 1080 },
        // aspectRatio: 1.777777778,
      },
      audio: true,
    };

    this.setState({ videoOptions: videoOptions, videoFacing: e.target.value });
  };

  async getCameraStream() {
    this.setState({ numberRecordings: "" });
    var audio = new Audio(ringtone);

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    if (
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia != undefined
    ) {
      await navigator.mediaDevices
        .getUserMedia(this.state.videoOptions)
        .then((stream) => {
          this.stream = stream;
          this.chunks = [];
          this.video.srcObject = stream;
          this.video.muted = true;
          this.video.volume = 0;

          audio.play();
          this.setState({ seconds: 3, isVideoRecoding: true });

          this.video.play();

          this.setState({ recording: true });

          this.myInterval = setInterval(() => {
            if (this.state.seconds > 0) {
              audio.play();

              this.setState({ seconds: this.state.seconds - 1 });
            } else if (this.state.seconds <= 0) {
              clearInterval(this.myInterval);

              this.setState({ seconds: this.state.seconds - 1 });
              this.countRef.current = setInterval(() => {
                this.setState({ timer: this.state.timer + 1 });
              }, 1000);
              // listen for data from media recorder
              this.setState({ recordingAfter: true });
              this.setState({ isActive: true, isPaused: true });

              if (iosCheck == false) {
                this.mediaRecorder = new MediaRecorder(stream, {
                  mimeType:
                    `${browserName}` == "Safari" || `${browserName}` == "safari"
                      ? videoIOSType
                      : videoWebType,
                });
              } else {
                this.mediaRecorder = new MediaRecorder(stream, {
                  mimeType: videoIOSType,
                });
              }

              this.mediaRecorder.start(10);
              watch.start();

              this.setState({ stream: this.mediaRecorder.stream });

              let interval = 0;
              setInterval(() => {
                // console.log(
                //   watch.duration().seconds() -( interval == 59
                //     ? (interval = interval + 60)
                //     : null),
                //   watch.duration().seconds(),
                //   interval,
                //   "in123465"
                // );
                if (watch.isRunning()) {
                  // console.log((watch.duration().hours().toString()).split(".")[0]+":"+(watch.duration().minutes().toString()).split(".")[0]+":"+(((watch.duration().seconds()-interval)).toString()).split(".")[0]);
                  this.state.recordingMiliSeconds = watch
                    .duration()
                    .millis()
                    .toString()
                    .split(".")[0];
                  this.state.recordingHours = watch
                    .duration()
                    .hours()
                    .toString()
                    .split(".")[0];
                  this.state.recordingMinutes = watch
                    .duration()
                    .minutes()
                    .toString()
                    .split(".")[0];
                  // console.log("this.state.record_click= " +this.state.record_click +" = " +watch.duration().seconds() + " " +interval );

                  let new_time = "";
                  let plus_point = 0;
                  if (this.state.record_click == "retaik" || "nextQuestion") {
                    let nnn = watch.duration().seconds();

                    if (nnn > 58 && nnn < 118) {
                      this.setState({ isRetakeSec: true }); //code: to update interval between 60secto 120 sec  used boolean isRetakeSec
                    } else {
                      this.setState({ isRetakeSec: false });
                    }

                    if (nnn < 60) {
                      if (interval > 0) {
                        plus_point = interval > 60 ? interval : 60;
                      }

                      new_time =
                        watch.duration().seconds() -
                        interval +
                        parseInt(plus_point);
                    } else {
                      // console.log("OUT");
                      new_time = watch.duration().seconds() - interval;
                    }
                  } else {
                    new_time = watch.duration().seconds() - interval;
                  }
                  this.state.recordingSeconds = new_time
                    .toString()
                    .split(".")[0];

                  if (this.state.isRetakeSec) {
                    //code: to update interval between 60svec to 120 sec  based on  boolean isRetakeSec
                    interval = 60;
                  } else if (
                    !this.state.isRetakeSec &&
                    watch.duration().seconds().toString().split(".")[0] -
                      interval ==
                      59
                  ) {
                    interval = interval + 60;
                  }

                  // if (
                  //   watch.duration().seconds().toString().split(".")[0] -
                  //     interval ==
                  //   59
                  // ) {
                  //   interval = interval + 60;
                  // }
                }

                // console.log(interval);
              }, 1000);

              this.mediaRecorder.ondataavailable = (e) => {
                if (e.data && e.data.size > 0) {
                  // const nanoseconds = this.mediaRecorder.nanos();
                  // console.log("Duration is", duration(nanoseconds).format())

                  // this.state.recordingMiliSeconds++;
                  // if (this.state.recordingMiliSeconds >= 20) {
                  //     this.state.recordingSeconds++;
                  //     this.state.recordingMiliSeconds = 0;
                  // }
                  // if (this.state.recordingSeconds >= 60) {
                  //     this.state.recordingMinutes++;
                  //     this.state.recordingSeconds = 0;
                  // }
                  // if (this.state.recordingMinutes >= 60) {
                  //     this.state.recordingHours++;
                  //     this.state.recordingMinutes = 0;
                  // }

                  // this.state.recordingMiliSeconds=Math.round(watch.duration().millis());
                  // this.state.recordingHours=Math.round(watch.duration().hours());
                  // this.state.recordingMinutes=Math.round(watch.duration().minutes());
                  // this.state.recordingSeconds=Math.round(watch.duration().seconds());
                  this.chunks.push(e.data);
                }
              };
              //  alert(this.chunks);
              return stream;
            }
          }, 1000);
        })
        .catch((err) => {
          
          if(err.name === 'NotAllowedError') {
           // alert("Please enable your web camera and microphone in order to create eternaview.");
           $.alert({
            title: 'Alert!',
            content: 'Please enable your camera and/or microphone in order to create your Eternaview.',
          });
            this.setState({ hasWebcam: false, hasMicrophone: false });
          } else if(err.name === 'NotFoundError') {
           // alert("Camera not found.");
            $.alert({
              title: 'Alert!',
              content: 'Webcam not found',
            });


            if(err.message.includes('video')) {
             // alert("Camera not found.");
              $.alert({
                title: 'Alert!',
                content: 'Webcam not found',
              });
              this.setState({ hasWebcam: false });
            }
            if(err.message.includes('audio')) {
              //alert("Mic not found.");
              $.alert({
                title: 'Alert!',
                content: 'Microphone not found',
              });
              this.setState({ hasMicrophone: false });
            } if (err.message.includes('requested device not found')) {
              // Handle case when requested device is not found
              $.alert({
                title: 'Alert!',
                content: 'Requested device not found. Please make sure the device is connected and properly configured.',
              });
              //alert("Requested device not found. Please make sure the device is connected and properly configured.");
              // Optionally, update state or perform additional actions based on this specific error
          }
          } else {
            alert("An error occurred: " + err.message);
          }
          return false;

          // alert(
          //   "Please enable your webcamera and microphone in order to create eternaview."
          // );
          // this.setState({ hasWebcam: false });
          // this.setState({ hasMicrophone: false });
          // return false;
          //throw new Error("Unable to fetch stream " + err);
        });
    } else {
      //alert("getUserMedia not supported on your browser!");
      $.alert({
        title: 'Alert!',
        content: 'getUserMedia not supported on your browser!',
      });
    }
  }
  msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs;
  }
  async getAudioStream() {
    this.setState({ numberRecordings: "" });
    var audiosound = new Audio(ringtone);

    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.setState({ isVideoRecoding: true });
        this.stream = stream;
        this.chunks = [];
        this.audio.srcObject = stream;

        this.audio.muted = true;

        audiosound.play();
        this.setState({ seconds: 3 });

        this.setState({ recording: true });

        this.myInterval = setInterval(() => {
          if (this.state.seconds > 0) {
            audiosound.play();
            this.setState({ seconds: this.state.seconds - 1 });
          } else if (this.state.seconds <= 0) {
            clearInterval(this.myInterval);
            this.setState({ seconds: this.state.seconds - 1 });
            this.countRef.current = setInterval(() => {
              this.setState({ timer: this.state.timer + 1 });
            }, 1000);
            // listen for data from media recorder
            this.setState({ recordingAfter: true });
            this.setState({ isActive: true, isPaused: true });
            this.setState({ detectAudio: stream });

            // if (iosCheck == true || checkSafari == true) {
            //     AudioRecorder.encoder = mpegEncoder
            //     AudioRecorder.prototype.mimeType = 'audio/mpeg'
            //     window.MediaRecorder = AudioRecorder
            // }

            this.mediaRecorder = new MediaRecorder(stream);

            this.mediaRecorder.start(10);
            watch.reset();
            watch.start();

            this.setState({ stream: this.mediaRecorder.stream });

            // this.mediaRecorder.ondataavailable = e => {
            //     if (e.data && e.data.size > 0) {
            //         this.chunks.push(e.data);
            //     }
            // };
            this.startRecordingTimer();
            this.mediaRecorder.addEventListener("dataavailable", (e) => {
              // alert(e.data.type);
              if (e.data && e.data.size > 0) {
                // this.state.recordingMiliSeconds++;
                // if (this.state.recordingMiliSeconds >= 17) {
                //     this.state.recordingSeconds++;
                //     this.state.recordingMiliSeconds = 0;
                // }
                // if (this.state.recordingSeconds >= 60) {
                //     this.state.recordingMinutes++;
                //     this.state.recordingSeconds = 0;
                // }
                // if (this.state.recordingMinutes >= 60) {
                //     this.state.recordingHours++;
                //     this.state.recordingMinutes = 0;
                // }
                // this.state.recordingMiliSeconds=Math.round(watch.duration().millis());
                // this.state.recordingHours=Math.round(watch.duration().hours());
                // this.state.recordingMinutes=Math.round(watch.duration().minutes());
                // this.state.recordingSeconds=Math.round(watch.duration().seconds());

                this.chunks.push(e.data);
              }
            });

            return stream;
          }
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        //alert("Please enable your webcamera and microphone in order to create eternaview.");
        $.alert({
          title: 'Alert!',
          content: 'Please enable your camera and/or microphone in order to create your Eternaview.',
        });
        this.setState({ hasWebcam: false });
        this.setState({ hasMicrophone: false });
        return false;
        //throw new Error("Unable to fetch stream " + err);
      });
  }

  startRecording(e) {
    e.preventDefault();

    this.handleReset();

    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    watch.reset();
    //this.player.record().start();
    if (this.state.typecreate == 0 && this.state.recordingAfter == false) {
      this.getCameraStream();
    } else {
      this.getAudioStream();
    }
  }

  getTimeDuration = () => {
    let totalSeconds;
    if (
      this.state.recordingHours === 0 &&
      this.state.recordingMinutes === 0 &&
      this.state.recordingSeconds === 0
    ) {
      totalSeconds = new Date(this.state.duration * 1000)
        .toISOString()
        .substr(11, 8);
    } else {
      const hours = this.format(this.state.recordingHours) * 3600;
      const minutes = this.format(this.state.recordingMinutes) * 60;
      totalSeconds =
        parseInt(this.format(this.state.recordingSeconds)) +
        parseInt(hours) +
        parseInt(minutes);
    }
    return totalSeconds;
  };

  stopRecording(e) {
    e.preventDefault();
    if (watch.isRunning()) watch.stop();

    this.handlePause();
    this.setState({
      timer: 0,
      recording: false,
      numberRecordings: "1",
      recordingAfter: false,
      isProcessing: true,
      recordingMiliSeconds: 0,
      recordingSeconds: 0,
      recordingMinutes: 0,
      recordingHours: 0,
    });

    // console.log(this.state);

    if (this.state.typecreate == 0 || this.state.typecreate == "0") {
      this.video.srcObject = this.video.src = null;
      this.video.muted = false;
      this.video.volume = 1;
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
      if (iosCheck == false) {
        const blob = new Blob(this.chunks, {
          type:
            `${browserName}` == "Safari" || `${browserName}` == "safari"
              ? videoIOSType
              : videoWebType,
        });

        this.setState({ recordedVideo: blob });
        //  const videoURL = window.URL.createObjectURL(blob);
        const videoURL = window.webkitURL.createObjectURL(blob);

        this.setState({ videoUrl: videoURL });

        let that = this;

        // For Duration
        const duration = this.getTimeDuration();
        that.setState({ duration: duration, isProcessing: false });
        /*  getBlobDuration(blob)
          .then(function (duration) {
            that.setState({ duration: duration, isProcessing: false });
            console.log(new Date(duration * 1000).toISOString().substr(11, 8));
          })
          .catch((error) => {
            console.error(error);
          }); */
      } else {
        const blob = new Blob(this.chunks, { type: "video/mp4" });

        this.setState({ recordedVideo: blob });
        //  const videoURL = window.URL.createObjectURL(blob);
        const videoURL = window.webkitURL.createObjectURL(blob);
        // alert(videoURL);
        this.setState({ videoUrl: videoURL });

        let that = this;
        //   setTimeout(
        //     function() {
        //       that.setState({ duration: 4, isProcessing: false });
        //     }
        //     .bind(this),
        //     3000
        // );
        const duration = this.getTimeDuration();
        that.setState({ duration: duration, isProcessing: false });
        /*  getBlobDuration(blob)
          .then(function (duration) {
            that.setState({ duration: duration, isProcessing: false });
          })
          .catch((error) => {
            // alert(JSON.stringify(error));

            console.error(error);
          }); */
      }
      if (iosCheck == false) {
        this.state.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    } else if (this.state.typecreate == 1 || this.state.typecreate == "1") {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }

      //const blob = new Blob(this.chunks, { type: audioType });
      const blob = new Blob(this.chunks, {
        type:
          `${browserName}` == "Safari" ||
          `${browserName}` == "safari" ||
          `${browserName}` == "Mobile Safari"
            ? "audio/mp4"
            : audioType,
      });

      this.setState({ recordedVideo: blob });
      this.setState({ detectAudio: null });

      const audioUrl = window.URL.createObjectURL(blob);
      this.setState({ audioUrl: audioUrl });
      let that = this;
      //   setTimeout(
      //     function() {
      //       that.setState({ duration: 4, isProcessing: false });
      //     }
      //     .bind(this),
      //     3000
      // );
      const duration = this.getTimeDuration();
      that.setState({ duration: duration, isProcessing: false });
      /*  getBlobDuration(blob)
        .then(function (duration) {
          console.log(duration);
          that.setState({ duration: duration, isProcessing: false });
        })
        .catch((error) => {
          console.error(error);
        }); */

      this.audio.src = this.audio.srcObject = null;
      this.audio.muted = false;

      if (iosCheck == false && checkSafari == false) {
        this.state.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
  }

  retakeVideo(e) {
    e.preventDefault();
    interval = 0;
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }

    this.setState({
      showRecording: true,
      duration: "",
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      numberRecordings: "",
      current_time_recording: "",
      newRecording: false,
      isProcessing: false,
      record_click: "retaik",
    });
    // this.setState({ seconds: 4 });
    this.setState({ timer: 0, duration: "", isProcessing: false });
    watch.reset();
    if (this.state.typecreate == 0) {
      this.getCameraStream();
    } else {
      this.getAudioStream();
    }
  }

  deserializeArray(cookieValue) {
    return JSON.parse(cookieValue);
  }
  // Function to get the array from the cookie
  getArrayFromCookie(cookieName) {
    const cookieValue = Cookies.get(cookieName);
    return cookieValue ? this.deserializeArray(cookieValue) : [];
  }

  onDirectionContinue = () => {
    const loggedUser = this.props.auth.loginUserInfo.email;
    let emails = [];
    if (Cookies.get("registered_emails")) {
      emails = this.getArrayFromCookie("registered_emails");
      const cookie_data = Cookies.get("registered_emails");
      emails = cookie_data ? JSON.parse(cookie_data) : [];
      const isExist = emails.find((resp) => resp == loggedUser);
      if (isExist) {
        this.setState({ isDirectionOpen: false });
      } else {
        emails.push(loggedUser);
        Cookies.set("registered_emails", JSON.stringify(emails), {
          expires: 31,
        });
      }
    } else {
      emails.push(loggedUser);
      Cookies.set("registered_emails", JSON.stringify(emails), { expires: 31 });
    }
    // console.log();
    this.setState({ isDirectionOpen: false });
    console.log(this.props.auth.loginUserInfo.email, "<- this.props.auth");
    // Cookies.set('isDirectionRead', true, { expires: 31 });
  };
  checkCookie = () => {
    const loggedUser = this.props.auth.loginUserInfo.email;
    if (!Cookies.get("isDirectionRead")) {
      let emails = [];
      emails = this.getArrayFromCookie("registered_emails");
      const cookie_data = Cookies.get("registered_emails");
      emails = cookie_data ? JSON.parse(cookie_data) : [];
      const isExist = emails.find((resp) => resp == loggedUser);
      if (isExist) {
        this.setState({ isDirectionOpen: false });
      } else {
        this.setState({ isDirectionOpen: true });
      }
    }
  };

  skipQuestion = (e) => {
    this.setState({ showSkip: false, loaded: false });
    if (this.state.isVideoRecoding) {
      this.stopRecording(e);
    }
    this.setState({
      timer: 0,
      duration: 0,
      recording: false,
      numberRecordings: "",
    });
    this.props.skipCreateQuestion(
      {
        question_id: this.state.data.id,
        type: this.state.type,
        view_id: this.state.viewId,
      },
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        if (this.state.completedata.next != null) {
          this.setState({ seconds: 4 });
          this.setState({ timer: 0, duration: "" });
          this.getQuestion(
            this.state.category,
            this.state.subcategory,
            "",
            this.state.type,
            this.state.viewId
          );
        } else if (this.state.completedata.next == null) {
          if (
            this.state.completedata.current_module ==
            this.state.completedata.total_sub_module
          ) {
            this.setState({ transition: "yes" });
            this.getQuestion(
              this.state.category,
              this.state.subcategory,
              "",
              this.state.type,
              this.state.viewId
            );
          } else {
            this.getNextModule();
          }
        }
      }
    );

    e.preventDefault();
  };

  
  skipModule = (e) => {
    this.setState({ showSkip: false });
    e.preventDefault();
    this.getNextModule();
  }
  navigateAway(e) {
    e.preventDefault();

    // this.props.history.replace("/");
    window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
  }

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ total_time: false, grace_total_time: false });
  };

  getNextModule() {
    this.setState({ loaded: false });
    this.props.skipCurrentModule(
      this.state.category,
      this.state.subcategory,
      this.state.viewId,
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        if (res.data) {
          this.setState({ seconds: 4 });
          this.setState({ timer: 0, duration: "" });
          this.getQuestion(
            this.state.category,
            res.data.next_id,
            "",
            this.state.type,
            this.state.viewId
          );
        } else {
          if (this.state.completedata.next == null) {
            if (
              this.state.completedata.current_module ==
              this.state.completedata.total_sub_module
            ) {
              this.setState({ transition: "yes" });
              this.getQuestion(
                this.state.category,
                this.state.subcategory,
                "",
                this.state.type,
                this.state.viewId
              );
            } else {
              this.props.history.replace(
                `/${this.state.baseName}/create/congratulations/${this.state.category}+${this.state.subcategory}+${this.state.type}+${this.state.plan_id}+${this.state.viewId}`
              );
            }
          } else {
            this.props.history.replace(
              `/${this.state.baseName}/create/congratulations/${this.state.category}+${this.state.subcategory}+${this.state.type}+${this.state.plan_id}+${this.state.viewId}`
            );
          }
        }
      }
    );
  }

  takeBreak = (e) => {
    e.preventDefault();

    this.props.history.replace("/home/myeternaviews/");
  };

  formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  handlePause = () => {
    clearInterval(this.countRef.current);
    this.setState({ isPaused: false });
  };

  handleReset = () => {
    clearInterval(this.myInterval);
    clearInterval(this.countRef.current);
    this.setState({ isActive: false, isPaused: false });
    this.setState({ timer: 0, duration: "" });
  };

  goToFaq = (e) => {
    e.preventDefault();

    this.props.history.push("/home/faq/");
  };

  goToBlog = (e) => {
    e.preventDefault();

    this.props.history.push("/home/blogs/");
  };

  componentWillUnmount() {
    clearInterval(this.countRef.current);
    clearInterval(this.myInterval);
    clearInterval(interval);
  }

  redirectToDemo = () => {
    window.open("/#eterna_work", "_blank").focus();
  };

  render() {
    const {
      showSkip,
      recording,
      recordingAfter,
      data,
      completedata,
      videoUrl,
      audioUrl,
      numberRecordings,
      seconds,
      loaded,
      transition,
      moduleQuestions,
      moveAheadModal,
    } = this.state;

    return (
      <>
        <HeaderComponent
          value={this.state.numberRecordings}
          saveToServer={this.saveToServer}
          skipQuestion={this.skipQuestion}
          props={this.props}
        />

        <div className={"rotten " + this.state.screen_mode}>
          <section className="practing_outer record2 recored3 paid5 recording1 hide_hand create_page crey78 top-border">
            <Loader loaded={loaded}></Loader>
            <div className="container">
              <div className="row">
                <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                  <div className="middle_outer56 text5627 full_intr45">
                    {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
                                        Open modal
                                    </button> */}
                    <div className="modal" id="myModal">
                      <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Before skipping ahead, do you wish to submit the
                              recording you just made?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="btn_inner">
                              <ul>
                                <li>
                                  <button
                                    className="btn custom_yes"
                                    onClick={(e) => this.saveToServer(e)}
                                    data-dismiss="modal"
                                  >
                                    Yes, submit!
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn custom_no"
                                    onClick={(e) => this.skipQuestion(e)}
                                    data-dismiss="modal"
                                  >
                                    No, just move ahead
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal" id="myBreakModal">
                      <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Before taking a break, do you wish to submit the
                              recording you just made?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="btn_inner">
                              <ul>
                                <li>
                                  <button
                                    className="btn custom_yes"
                                    onClick={(e) => this.saveToServer(e)}
                                    data-dismiss="modal"
                                  >
                                    Yes, submit!
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn custom_no"
                                    onClick={(e) => this.takeBreak(e)}
                                    data-dismiss="modal"
                                  >
                                    No, just take break
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal" id="myModalModule">
                      <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Before skipping ahead, do you wish to submit the
                              recording you just made?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="btn_inner">
                              <ul>
                                <li>
                                  <button
                                    className="btn custom_yes"
                                    onClick={(e) => this.saveToServer(e)}
                                    data-dismiss="modal"
                                  >
                                    Yes, submit!
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn custom_no"
                                    onClick={(e) => this.skipModule(e)}
                                    data-dismiss="modal"
                                  >
                                    No, just move ahead
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        moveAheadModal == true
                          ? "modal fade show custom_modal"
                          : "modal fade show show_custom_modal"
                      }
                      id="myMoveModal"
                      role="dialog"
                    >
                      <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Before you navigate away, do you wish to submit
                              the recording you just made?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="btn_inner">
                              <ul>
                                <li>
                                  <button
                                    className="btn custom_yes"
                                    onClick={(e) => this.saveToServer(e)}
                                    data-dismiss="modal"
                                  >
                                    Yes, submit!
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn custom_no"
                                    onClick={(e) => this.navigateAway(e)}
                                    data-dismiss="modal"
                                  >
                                    No, just navigate away.
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal201"
                      className={
                        this.state.total_time == true
                          ? "modal fade show custom_modal"
                          : "modal fade show show_custom_modal"
                      }
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close custom_close3"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              Please note that you have consumed maximum
                              recording time limit and left with only 1 hour.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button type="button" onClick={this.closePopUp}>
                              Okay!
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal201"
                      className={
                        this.state.grace_total_time == true
                          ? "modal fade show custom_modal"
                          : "modal fade show show_custom_modal"
                      }
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close custom_close3"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              Please note that you have consumed maximum
                              recording time limit along with additional time
                              given as grace allotment and left with only 1
                              hour.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button type="button" onClick={this.closePopUp}>
                              Okay!
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Direction
                      isOpen={this.state.isDirectionOpen}
                      closeDirection={this.onDirectionContinue}
                    />

                    {transition == "" && (
                      <h3 className="small_heding69">
                        <span class="module-number">
                        Module {completedata.current_module}/
                        {completedata.total_sub_module} :{" "}</span>
                        { !isMobile  ||  this.state.screen_mode != "portrait" ?
                        <span class="module-name">

                        {data?.sub_category?.name}
                        </span>:<></>}
                      </h3>
                    )}

                    {transition == "yes" && (
                      <h3 className="small_heding69">
                        {data?.sub_category?.name}
                      </h3>
                    )}


                        <SkipModuleButton
          recording={recording}
          numberRecordings={numberRecordings}
          data={data}
          showSkip={showSkip}
          completedata={completedata}
          skipModule={this.skipModule}
        />

{  isMobile &&  this.state.screen_mode === "portrait"?
                        <span class="module-name">

                        {data?.sub_category?.name}
                        </span>:<></>}
             
                    <div className="tex_fulll2 row">
                      <div className={  isMobile &&  this.state.screen_mode === "portrait"?"col-12 col-md-3 ":"col-sm-3"}>
                        {" "}
                        {/* create-question-part-1 */}
                        <p className="question_name">
                          {" "}
                          Question {completedata.current_page} of{" "}
                          {completedata.total_question_count}
                          </p>
                          {isMobile &&    this.state.screen_mode === "portrait"?<SkipQuestionButton
                  recording={recording}
                  numberRecordings={numberRecordings}
                  data={data}
                  showSkip={showSkip}
                  isMobile={isMobile}
                  screen_mode={this.state.screen_mode}
                  skipQuestion={this.skipQuestion}
                />:<></>}

                        
                      </div>
                      <div className={  isMobile &&  this.state.screen_mode === "portrait"?"col-12 col-md-3 ":"col-sm-6"}>
                        {" "}
                        {/* create-question-part-2 */}
                        <h4>{data?.question}</h4>
                      </div>
                      <div className={  isMobile &&  this.state.screen_mode === "portrait"?"col-12 col-md-3 ":"col-sm-3"}>
                        {/* create-question-part-3 */}
                        <div className="div-skip-question">
                          {!isMobile ||  this.state.screen_mode != "portrait"?<SkipQuestionButton
                                    recording={recording}
                                    numberRecordings={numberRecordings}
                                    data={data}
                                    showSkip={showSkip}
                                    isMobile={isMobile}
                                    screen_mode={this.state.screen_mode}
                                    skipQuestion={this.skipQuestion}
                                  />:<></>}
                        
                        </div>
                      </div>
                    </div>

                    {numberRecordings == "" && recordingAfter == false && (
                      <div className="same_height">
                        {!recording &&
                        numberRecordings == "" &&
                        this.state.total_time_left == false ? (
                          <div className="btn7 custom_t6">
                            <button
                              type="button"
                              className="btn"
                              onClick={(e) => this.startRecording(e)}
                            >
                              START Recording
                            </button>
                          </div>
                        ) : (
                          <div className="btn7 custom_t6">
                            <button
                              type="button"
                              className="btn"
                              disabled={true}
                            >
                              START Recording
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    {!recording && numberRecordings != "" && (
                      <div className="btn7 custom_t6">
                        <button
                          type="button"
                          className="btn"
                          disabled={numberRecordings != ""}
                        >
                          START Recording
                        </button>
                      </div>
                    )}

                    {recording == true && recordingAfter == true && (
                      <div className="btn7 custom_t6 red_coloe5">
                        <button
                          type="button"
                          className="btn"
                          onClick={(e) => this.stopRecording(e)}
                        >
                          STOP Recording
                        </button>
                      </div>
                    )}

                    {this.state.typecreate == 0 && (
                      <div className="img6 audio_recorder custom_rec45">
                        {recordingAfter == true && (
                          <div className="recording_gif">
                            <img src={mic} />
                          </div>
                        )}
                        <div className="timer_outer">
                          {seconds == 1 && <img src={timer1} />}
                          {seconds == 2 && <img src={timer2} />}
                          {seconds == 3 && <img src={timer3} />}
                          {seconds == 0 && <img src={start} />}
                        </div>

                        {/* {numberRecordings == "" && recording == false && (
                          <div className="videoCheck">
                            {isMobileDevice && (
                              <select
                                className="changeCamera_select"
                                value={this.state.videoFacing}
                                onChange={(e) => this.videoOptionsChange(e)}
                              >
                                <option value="user">Front Camera</option>
                                <option value="environment">Rear Camera</option>
                              </select>
                            )}
                          </div>
                        )}

                        {numberRecordings == "" && (
                          <video
                            style={{objectFit:"fill", width: 900, height: 320 }}
                            ref={(v) => {
                              this.video = v;
                            }}
                            playsInline
                          >
                            Video stream not available.
                          </video>
                        )}

                        {numberRecordings != "" && (
                          <video
                            id="video_player"
                            style={{objectFit:"fill", width: 900, height: 320 }}
                            ref={(v) => {
                              this.videocreated = v;
                            }}
                            src={videoUrl}
                            controls
                            playsInline
                          ></video>
                        )} */}

                        <div className="contain-recording-player">
                          <div className="col-md-6 offset-md-3 col-sm-12 submit_retake_col">
                            <div className="btn_outer99 recor67 new_css">
                              <ul className="ul_retake">
                                {numberRecordings == "" &&
                                  recording == false && (
                                    <div className="videoCheck">
                                      {isMobileDevice && (
                                        <select
                                          className="changeCamera_select"
                                          value={this.state.videoFacing}
                                          onChange={(e) =>
                                            this.videoOptionsChange(e)
                                          }
                                        >
                                          <option value="user">
                                            Front Camera
                                          </option>
                                          <option value="environment">
                                            Rear Camera
                                          </option>
                                        </select>
                                      )}
                                    </div>
                                  )}
                                {this.state.isProcessing == true && (
                                  <li className="Save_outer4">
                                    <button className="btn">
                                      Processing
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {numberRecordings != "" &&
                                  this.state.isProcessing == false &&
                                  this.state.submitFlag == false && (
                                    <li className="Save_outer4">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.saveToServer(e)}
                                      >
                                        Submit
                                      </button>
                                    </li>
                                  )}

                                {this.state.submitFlag == true && (
                                  <li className="Save_outer4">
                                    <button className="btn">
                                      Submitting...
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {this.state.isSubmitted == false &&
                                  numberRecordings != "" && (
                                    <li className="cancel">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.retakeVideo(e)}
                                      >
                                        Retake
                                      </button>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>

                          {numberRecordings == "" && (
                            <div
                              className={
                                !isMobile
                                  ? "create_not_mobile"
                                  : isMobile &&
                                    this.state.screen_mode === "portrait"
                                  ? "create_VideoPortrait"
                                  : "create_VideoFrame"
                              }
                            >
                              <video
                                id="video_player"
                                style={
                                  isMobile &&
                                  this.state.screen_mode === "portrait"
                                    ? {
                                        objectFit: "fill",
                                        // width: 900,
                                        // height: 320,
                                      }
                                    : { objectFit: "fill" }
                                }
                                ref={(v) => {
                                  this.video = v;
                                }}
                                // controls
                                playsInline
                                webkit-playsinline="true"
                                 x5-playsinline="true"
                              >
                                Video stream not available.
                              </video>
                            </div>
                          )}

                          {numberRecordings != "" && (
                            <div
                              className={
                                !isMobile
                                  ? ""
                                  : isMobile &&
                                    this.state.screen_mode === "portrait"
                                  ? ""
                                  : "create_VideoFrame"
                              }
                            >
                              <video
                                id="video_player"
                                style={
                                  isMobile &&
                                  this.state.screen_mode === "portrait"
                                    ? {
                                        objectFit: "fill",
                                        // width: 900,
                                        // height: 320,
                                      }
                                    : { objectFit: "fill" }
                                }
                                ref={(v) => {
                                  this.videocreated = v;
                                }}
                                src={videoUrl}
                                controls
                                playsInline
                              ></video>
                            </div>
                          )}
                        </div>

                        <div className="row retake_row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="text_nam546">
                              <p>
                                Recording Time of This Question:
                                {/* {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} */}
                                {this.state.recordingHours === 0 &&
                                this.state.recordingMinutes === 0 &&
                                this.state.recordingSeconds === 0
                                  ? new Date(this.state.duration * 1000)
                                      .toISOString()
                                      .substr(11, 8)
                                  : this.format(this.state.recordingHours) +
                                    ":" +
                                    this.format(this.state.recordingMinutes) +
                                    ":" +
                                    this.format(this.state.recordingSeconds)}
                                <br />
                                Cumulative Recording Time:{" "}
                                {completedata.total_time_recording
                                  ? completedata.total_time_recording
                                  : "00:00:00"}
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6 col-6">
                            <div className="btn_outer99 recor67">
                              <ul className="ul_retake">
                                {this.state.isProcessing == true && (
                                  <li className="Save_outer4">
                                    <button className="btn-secondary">
                                      Processing
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {numberRecordings != "" &&
                                  this.state.isProcessing == false &&
                                  this.state.submitFlag == false && (
                                    <li className="Save_outer4">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.saveToServer(e)}
                                      >
                                        Submit
                                      </button>
                                    </li>
                                  )}

                                {this.state.submitFlag == true && (
                                  <li className="Save_outer4">
                                    <button className="btn">
                                      Submitting...
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {this.state.isSubmitted == false &&
                                  numberRecordings != "" && (
                                    <li className="cancel">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.retakeVideo(e)}
                                      >
                                        Retake
                                      </button>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div> */}
                        </div>

                        <div className="take_break">
                          <a
                            onClick={(e) => this.takeBreak(e)}
                            href="/home/myeternaviews"
                          >
                            <img src={chat_tak} />
                          </a>
                        </div>

                        {/*  {(!recording && numberRecordings == '' && transition == '') && <div className="take_break" onClick={e => this.takeBreak(e)}>
                                            <a href="/home/myeternaviews"><img src={chat_tak} /></a>
                                        </div>}
                                        {(!recording && numberRecordings != '' && transition == '') && <div className="take_break" data-toggle="modal" data-target="#myBreakModal">
                                            <img src={chat_tak} />
                                        </div>} */}
                      </div>
                    )}

                    {localStorage.getItem("typecreate") == 1 && (
                      <div className="img6 audio_recorder custom_rec45 separate-style-audio">
                        <div className="audio_div_box">
                          {recordingAfter == true && (
                            <div className="recording_audio_gif">
                              <img src={mic} />
                            </div>
                          )}

                          {this.state.detectAudio ? (
                            <AudioAnalyser audio={this.state.detectAudio} />
                          ) : (
                            ""
                          )}
                          {/* {this.state.flagFrame == false && <img src={video_recording} />} */}

                          <div className="timer_outer">
                            {seconds == 1 && <img src={timer1} />}
                            {seconds == 2 && <img src={timer2} />}
                            {seconds == 3 && <img src={timer3} />}
                            {seconds == 0 && <img src={start} />}
                          </div>

                          {numberRecordings == "" && (
                            <audio
                              ref={(a) => {
                                this.audio = a;
                              }}
                            >
                              <p>Audio stream not available. </p>
                            </audio>
                          )}

                          {numberRecordings != "" && (
                            <audio
                              controls
                              src={audioUrl}
                              controlsList="nodownload"
                            />
                          )}
                        </div>

                        <div className="row retake_row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="text_nam546">
                              <p>
                                Recording Time of This Question:
                                {/* {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} */}
                                {this.state.recordingHours === 0 &&
                                this.state.recordingMinutes === 0 &&
                                this.state.recordingSeconds === 0
                                  ? new Date(this.state.duration * 1000)
                                      .toISOString()
                                      .substr(11, 8)
                                  : this.format(this.state.recordingHours) +
                                    ":" +
                                    this.format(this.state.recordingMinutes) +
                                    ":" +
                                    this.format(this.state.recordingSeconds)}
                                <br />
                                Cumulative Recording Time:{" "}
                                {completedata.total_time_recording
                                  ? completedata.total_time_recording
                                  : "00:00:00"}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="btn_outer99 recor67">
                              <ul className="ul_retake">
                                {this.state.isProcessing == true && (
                                  <li className="Save_outer4">
                                    <button className="btn-secondary">
                                      Processing
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {numberRecordings != "" &&
                                  this.state.isProcessing == false &&
                                  this.state.submitFlag == false && (
                                    <li className="Save_outer4">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.saveToServer(e)}
                                      >
                                        Submit
                                      </button>
                                    </li>
                                  )}

                                {this.state.submitFlag == true && (
                                  <li className="Save_outer4">
                                    <button className="btn">
                                      Submitting...
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {this.state.isSubmitted == false &&
                                  numberRecordings != "" && (
                                    <li className="cancel">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.retakeVideo(e)}
                                      >
                                        Retake
                                      </button>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {!recording &&
                          numberRecordings == "" &&
                          transition == "" && (
                            <div
                              className="take_break"
                              onClick={(e) => this.takeBreak(e)}
                            >
                              <a href="/home/myeternaviews">
                                <img src={chat_tak} />
                              </a>
                            </div>
                          )}
                        {!recording &&
                          numberRecordings != "" &&
                          transition == "" && (
                            <div
                              className="take_break"
                              data-toggle="modal"
                              data-target="#myBreakModal"
                            >
                              <img src={chat_tak} />
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="greand_pop">
            <div className="conatiner">
              <div className="img56">
                {!recording && numberRecordings == "" && transition == "" && (
                  <a data-toggle="modal" data-target="#myModal12">
                    <img src={hand_outer} />
                  </a>
                )}

                {!recording && numberRecordings != "" && transition == "" && (
                  <a data-toggle="modal" data-target="#myBreakModal">
                    <img src={hand_outer} />
                  </a>
                )}

                <div className="modal custom_create_popup" id="myModal12">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body cu678">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="box activer4 create-info-faq">
                              <h4>Frequently Asked Questions</h4>
                              <div className="few3_outer">
                                <i className="fas fa-circle"></i>
                                <p>Q: What is an Eternaview?</p>
                              </div>
                              <div className="few3_outer no_bo675">
                                <i className="fas fa-circle"></i>
                                <p>
                                  Q. Is the response real or some sort of
                                  deepfake or other artificially generated
                                  response?
                                </p>
                              </div>
                              <div className="few3_outer no_bo675">
                                <i className="fas fa-circle"></i>
                                <p>
                                  Q: Why do I need Eternaview for creating an
                                  interview versus other options?
                                </p>
                              </div>
                              <div className="few3_outer no_bo675">
                                <i className="fas fa-circle"></i>
                                <p>
                                  Q: What types of Eternaviews can be created?
                                </p>
                              </div>
                              <div className="few3_outer no_bo675">
                                <i className="fas fa-circle"></i>
                                <p>
                                  Q. Can I see the interview questions before I
                                  subscribe?
                                </p>
                              </div>

                              <div className="btn78">
                                <a
                                  href=""
                                  data-dismiss="modal"
                                  onClick={this.goToFaq}
                                >
                                  View all
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="box">
                              <h4>Scan Modules & Questions in Template</h4>
                              <div className="custtom_according acc_cus1">
                                <div className="bs-example">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    {moduleQuestions?.map((subcat, ind) => {
                                      return (
                                        <div className="card" key={subcat.id}>
                                          <div
                                            className="card-header"
                                            id={"headingOne" + ind}
                                          >
                                            <h2 className="mb-0">
                                              <button
                                                type="button"
                                                className="btn btn-link"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapseOne" + ind
                                                }
                                              >
                                                {subcat.subcategory_name}
                                                <i className="fa fa-angle-down"></i>{" "}
                                              </button>
                                            </h2>
                                          </div>
                                          <div
                                            id={"collapseOne" + ind}
                                            className="collapse"
                                            aria-labelledby={"headingOne" + ind}
                                            data-parent="#accordionExample"
                                          >
                                            <div className="card-body cus56">
                                              {subcat.questions.map(
                                                (ques, index) => {
                                                  return (
                                                    <p key={ques.id}>
                                                      {ques.question}{" "}
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="box">
                              <h4>Demo Video</h4>
                              <div className="img6_outer create-demo-video-body">
                                {/*                                     <img src={ctre45} onClick={() => this.redirectToDemo()} />
                                 */}
                                <video
                                  style={{ height: "100%" }}
                                  id="a"
                                  ref={(node) => (this.video7Node = node)}
                                  className="video-js vjs-default-skin custom_video_js"
                                  options={videoJsOptions7}
                                  playsInline
                                ></video>

                                {/*    <ReactHlsPlayer 
                                    style={{height:'289px'}}
                                       className="video-js vjs-default-skin custom_video_js"
    src="https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8"
    autoPlay={false}
    controls={true}
    loop={false}
    width="100%"
  />  */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="box vill98">
                              <h4>Blog Posts</h4>
                              <div className="blog_box1">
                                {/*                                 <img src={blog1} />
                                 */}{" "}
                                <img src="https://eternaview-staging.s3.amazonaws.com/eternaview-staging/concentrated-dark-skinned-businessman-working-late-at-night-in-office-with-computer-SBI-_lk2XjOj.jpg" />
                                <div className="texcd4">
                                  <p>How to Optimize Your Video Recording</p>

                                  <span>
                                    <img src={arrow_right} />
                                  </span>
                                </div>
                              </div>
                              <div className="btn78">
                                <a
                                  href=""
                                  data-dismiss="modal"
                                  onClick={this.goToBlog}
                                >
                                  View all
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    overflow: "auto",
  },
  child: {
    flex: 2,
  },
  videos: {
    flex: 1,
  },
};

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
  saveCreateQuestion: bindActionCreators(saveCreateQuestion, dispatch),
  skipCreateQuestion: bindActionCreators(skipCreateQuestion, dispatch),
  getQuestionById: bindActionCreators(getQuestionById, dispatch),
  getCreateRestoreSession: bindActionCreators(
    getCreateRestoreSession,
    dispatch
  ),
  skipCurrentModule: bindActionCreators(skipCurrentModule, dispatch),
  scanQuestions: bindActionCreators(scanQuestions, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEternaview);
