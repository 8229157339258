import React, { Component } from 'react';
import RecorderJS from 'recorder-js';
import $ from 'jquery';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2"; // ES Modules import
import { getAudioStream, exportBuffer } from '../utilities/audio';
import red_mick from "../Images/red_mick.png";
import { browserName, browserVersion } from "react-device-detect";
import { saveAs } from 'file-saver';
import { convertAudioToText,getChatGptIntents,whisperAudioToText } from '../redux/actions';
import sample_audio from '../Sounds/no_sound.mp3';
import helloSound from '../Sounds/hello_dummy.wav';

import moment from 'moment';
import { connect } from "react-redux";
import * as lamejs from 'lamejs';

import { bindActionCreators } from "redux";

import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.js';

const pako = require('pako');

class RecorderTest extends Component {
    constructor(props) {
        super(props);
        this.audioBuffer = null;

        this.state = {
            chat_gpt_call: 0,
            sessionUID: null,
            gptResp :null,
            audioContext: null,
            analyser: null,
            mediaStream: null,
            isAnalyzing: false,
            audioStatus: 'Click "Start Analysis" to begin.',
            audioThreshold: 0.2, // Adjust this threshold as needed
            recorderbtnActive:false,
            stream: null,
            recording: 0,
            recorder: null,
            boatID: null,
            audioContext: null,
            botAliasId:'TSTALIASID',
        };
        this.gptRes = null
        this.startRecord = this.startRecord.bind(this);
        this.stopRecord = this.stopRecord.bind(this);
      //  this.handleWindowFocus = this.handleWindowFocus.bind(this);

    }
    

    async componentDidMount() {
        /* Hide LEX Start */
        // this.onloadTextDummyQuery();
       // this.onloadDummyWishperQuery();
        /* Hide LEX End */    

   /*      setInterval(() => {
            this.onloadDummyWishperQuery();
          }, 100000); */


        let stream;

        try {
            stream = await getAudioStream();
        } catch (error) {
            // Users browser doesn't support audio.
            // Add your handler here.
            console.log(error);
        }

        this.setState({ stream });
    }




    checkAudio(blob) {
       // alert('checkAudio');
        this.loadAudioData(blob)
            .then((buffer) => {
               // alert('recorderOutput.blob');

                if (this.isSilent(buffer)) {
                    $.alert({
                        title: 'Alert!',
                        content: 'The audio file is silent.',
                      });
                  //  alert("The audio file is silent.");
                } else {
                    console.log("The audio file is not silent.");
                }
            })
            .catch((error) => {
                console.error("Error loading audio data:", error);
                this.showError("Error loading audio file.");
            });
    }
    
    showError(message) {
        this.setState({ errorMessage: message });
    }
    isSilent(buffer, silenceThreshold = 0.01, durationThreshold = 1) {
        const channelData = buffer.getChannelData(0); // Check the first channel
        const sampleRate = buffer.sampleRate;
        const totalSamples = buffer.length;
    
        let silentSamples = 0;
    
        for (let i = 0; i < totalSamples; i++) {
            if (Math.abs(channelData[i]) < silenceThreshold) {
                silentSamples++;
            }
        }
    
        const silentDuration = silentSamples / sampleRate;
        return silentDuration >= durationThreshold;
    }
    loadAudioData(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                audioContext.decodeAudioData(reader.result, (buffer) => {
                    resolve(buffer);
                }, (error) => {
                    reject(error);
                });
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    }
    onloadDummyQuery() {
        // console.log('onloadDummyQuery audio');

        let blobaudio = { size: 20850, type: 'application/octet-stream' };
        // console.log('++++++++++++blobaudio++++++++++++++++');
        const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
            type: "application/json",
        });
        // console.log(audio);
        // console.log('++++++++++++wwwww++++++++++++++++');

        // Process the audio here.
        const command = new RecognizeUtteranceCommand({
            "botAliasId": "TSTALIASID",
            "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
            "localeId": "en_US",
            "inputStream": audio,
            "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
            "sessionId": "043758711856891",
            "responseContentType": "text/plain;charset=utf-8"
        });

        const response = this.props.client.send(command);
        // console.log('resp ->',response);
        // console.log(response);
        if (response.interpretations && response.interpretations.length > 0) {
            var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
            var inputTranscript = this.decompressGzip(response.inputTranscript);
        }


    }


async getBlobFromWav() {
    try {
        const response = await fetch(helloSound); // Make sure helloSound is a valid URL
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
        return blob; // Return the Blob
    } catch (error) {
        console.error('Error fetching the WAV file:', error);
    }
}


    onloadTextDummyQueryPREV() {
        // console.log('onloadTextDummyQuery');

       //// let blobaudio = { size: 20850, type: 'application/octet-stream' };
       let blobaudio = this.getBlobFromWav();//{ size: 20850, type: 'application/octet-stream' };

        // console.log('++++++++++++blobaudio++++++++++++++++');
        const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
            type: "application/json",
        });

       

        // console.log(audio);
        // console.log('++++++++++++wwwww++++++++++++++++');

        // Process the audio here.
       
        if(this.props.category == "Legacy")
        {
            this.setState({ boatID: "RMEHNYXF57" });

        }
        else if(this.props.category == "Dating")
        {
      
          this.setState({ boatID: "QPSJB0MH2O" });

        }
        else if(this.props.category == "Squirrel")
        {
     
          this.setState({ boatID: "ZZQU09YS8F" });

        }
        console.log('this.props.',this.props);
        const command = new RecognizeUtteranceCommand({
            "botAliasId": "TSTALIASID",
           // "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
           "botId":this.props.boatID==null?"RMEHNYXF57":this.state.boatID,
           "localeId": "en_US",
            "inputStream": 'text',
            "requestContentType": "text/plain; charset=utf-8",
            "sessionId": "043758711856891",
            "responseContentType": "text/plain;charset=utf-8"
        });

        const response = this.props.client.send(command);
        // console.log(response,"<- Dummy Query response ");
        if (response.interpretations && response.interpretations.length > 0) {
            var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
            var inputTranscript = this.decompressGzip(response.inputTranscript);
        }

      //  alert(this.state.sessionUID);

   
    }


    
    onloadTextDummyQuery() {
        // console.log('onloadTextDummyQuery');

        let blobaudio = { size: 20850, type: 'application/octet-stream' };
        // console.log('++++++++++++blobaudio++++++++++++++++');
        const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
            type: "application/json",
        });
        // console.log(audio);
        // console.log('++++++++++++wwwww++++++++++++++++');
        //alert(this.props.baseName)
        if(this.props.baseName=='home')
            {
                this.boatID = 'RMEHNYXF57';
                this.setState({ boatID: "RMEHNYXF57" });
            }
            else if(this.props.baseName=='dating')
            {
                this.boatID = 'QPSJB0MH2O';
                this.setState({ boatID: "QPSJB0MH2O" });
            }
            else if(this.props.baseName=='squirrel')
            {
                this.boatID = 'ZZQU09YS8F';
                this.setState({ boatID: "ZZQU09YS8F" });
            }
            else
            {
                this.boatID = 'RMEHNYXF57';
                this.setState({ boatID: "RMEHNYXF57" });
            }
        // Process the audio here.
        const command = new RecognizeUtteranceCommand({
            "botAliasId": "TSTALIASID",
           // "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
            "botId":this.boatID,
            "localeId": "en_US",
            "inputStream": 'text',
            "requestContentType": "text/plain; charset=utf-8",
            "sessionId": "043758711856891",
            "responseContentType": "text/plain;charset=utf-8"
        });

        const response = this.props.client.send(command);
        // console.log(response,"<- Dummy Query response ");
        if (response.interpretations && response.interpretations.length > 0) {
            var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
            var inputTranscript = this.decompressGzip(response.inputTranscript);
        }


    }

    async onloadDummyWishperQuery() {
        try {
            // Wait for the Blob to be returned
            const audioBlob = await this.getBlobFromWav();
            
            // Log the Blob to ensure it's being fetched properly
            console.log('audioBlob', audioBlob);
    
            // Create a FormData object and append the necessary fields
            let formData = new FormData();
            formData.append('audio', audioBlob); // Append the audio Blob
            formData.append('login_uuid', Math.floor(Math.random() * 1000000));
            formData.append('type', 'legacy');
            
            // Call the whisperAudioToText method and handle the response
            this.props.whisperAudioToText(formData, this.props.auth.loginUserToken, async (res) => {
              /*   this.props.textFromWhisper(res);
                this.gptRes = res;
                this.setState({ gptResp: res }); */
            });

            this.props.convertAudioToText(formData,this.props.auth.loginUserToken,async(res)=>{
                this.gptRes = res
               this.setState({gptResp:res})
             })
     
        } catch (error) {
            console.error('Error in onloadDummyWishperQuery:', error);
        }
    }
    generateWAVBlob = () => {
        // Parameters
        const sampleRate = 16000; // Sample rate in Hz
        const duration = 5; // Duration in seconds
        const frequency = 440; // Frequency of the sine wave
    
        // Generate samples
        const numSamples = sampleRate * duration;
        const samples = new Float32Array(numSamples);
        for (let i = 0; i < numSamples; i++) {
          samples[i] = Math.sin(2 * Math.PI * frequency * i / sampleRate);
        }
    
        // Convert to WAV format
        const buffer = new ArrayBuffer(44 + numSamples * 2);
        const view = new DataView(buffer);
    
        // RIFF chunk descriptor
        view.setUint32(0, 0x46464952, true); // "RIFF"
        view.setUint32(4, 36 + numSamples * 2, true); // ChunkSize
        view.setUint32(8, 0x45564157, true); // "WAVE"
    
        // fmt sub-chunk
        view.setUint32(12, 0x20746d66, true); // "fmt "
        view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
        view.setUint16(20, 1, true); // AudioFormat (1 for PCM)
        view.setUint16(22, 1, true); // NumChannels (1 for mono)
        view.setUint32(24, sampleRate, true); // SampleRate
        view.setUint32(28, sampleRate * 2, true); // ByteRate
        view.setUint16(32, 2, true); // BlockAlign
        view.setUint16(34, 16, true); // BitsPerSample
    
        // data sub-chunk
        view.setUint32(36, 0x61746164, true); // "data"
        view.setUint32(40, numSamples * 2, true); // Subchunk2Size
    
        // Write samples
        const offset = 44;
        for (let i = 0; i < numSamples; i++) {
          const s = Math.max(-1, Math.min(1, samples[i]));
          view.setInt16(offset + i * 2, s * 0x7FFF, true);
        }
    
        // Create a Blob
        return new Blob([view], { type: 'audio/wav' });
      };


      startRecord() {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new RecorderJS(audioContext);
                recorder.init(stream);
    
                this.setState({
                    recorder,
                    recording: 1,
                    recorderbtnActive: true,
                    sessionUID: Math.floor(Math.random() * 1000000)
                }, () => {
                    recorder.start();
                    this.startAudioAnalysis(stream, audioContext);
                });
            })
            .catch((error) => {
                $.alert({
                    title: 'Alert!',
                    content: 'Please make sure your microphone is connected and enabled.',
                });
            });
    }
    
    startRecord1() {
        const { stream } = this.state;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
        this.setState({ recording: 1,recorderbtnActive:true,sessionUID: Math.floor(Math.random() * 1000000) });


        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const recorder = new RecorderJS(audioContext,);
        recorder.init(stream);

        this.setState(
            {
                recorder
            },
            () => {
                recorder.start();
            }
        );
        this.startAudioAnalysis();

    })
    .catch((error) => {
     // console.error('Error accessing the microphone:', error);
      $.alert({
        title: 'Alert!',
        content: 'Please make sure your microphone is connected and enabled.',
      });
    });
    }



  startAudioAnalysis1 = () => {
    this.setState({
      isAnalyzing: true,
      audioStatus: 'Listening for audio...',
    });

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        analyser.fftSize = 256; // Adjust as needed
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        analyser.smoothingTimeConstant = 0.85;
        audioContext.createMediaStreamSource(stream).connect(analyser);
        let last_low_audioLevel = new Date()
        let last_high_audioLevel  = new Date()
        const updateAudioLevel = () => {
          analyser.getByteFrequencyData(dataArray);
          const audioLevel = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength / 256;
         
          if (audioLevel < this.state.audioThreshold) {
            last_low_audioLevel= new Date()
            var startTime = moment(last_high_audioLevel, 'DD-MM-YYYY hh:mm:ss');
            var endTime = moment(last_low_audioLevel, 'DD-MM-YYYY hh:mm:ss');
            var secondsDiff = endTime.diff(startTime, 'seconds');
            if(secondsDiff>4){
                this.stopRecord()
            }
          }else{
            last_high_audioLevel  = new Date()
          }

          if (this.state.isAnalyzing) {
            requestAnimationFrame(updateAudioLevel);
          }
        };

        updateAudioLevel();

        this.setState({
          audioContext,
          analyser,
          mediaStream: stream,
        });
      })
      .catch((error) => {
        console.error('Error accessing the microphone:', error);
      });
  }


  startAudioAnalysis = (stream, audioContext) => {
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    analyser.smoothingTimeConstant = 0.85;

    audioContext.createMediaStreamSource(stream).connect(analyser);

    this.setState({
        isAnalyzing: true,
        audioStatus: 'Listening for audio...',
        audioContext,
        analyser,
        mediaStream: stream
    });

    const updateAudioLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const audioLevel = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength / 256;

        if (audioLevel < this.state.audioThreshold) {
            const now = new Date();
            const secondsDiff = (now - this.state.lastHighAudioLevel) / 1000;

            if (secondsDiff > 4) {
                this.stopRecord();
            }
        } else {
            this.setState({ lastHighAudioLevel: new Date() });
        }

        if (this.state.isAnalyzing) {
            requestAnimationFrame(updateAudioLevel);
        }
    };

    updateAudioLevel();
}


  stopAudioAnalysis = () => {
    this.state.mediaStream.getTracks().forEach(track => track.stop());
    this.state.audioContext.close();

    this.setState({
      isAnalyzing: false,
      audioStatus: 'Analysis stopped.',
    });
  }


    async stopRecord() {
        this.stopAudioAnalysis()
        this.setState({ recorderbtnActive:false });

        if (document.getElementById('audioIteractor')) {


            document.getElementById('audioIteractor').setAttribute('src', sample_audio);
            document.getElementById('audioIteractor').play();
        }

      
        const { recorder } = this.state;
        // const test = await recorder.stop();
        const recorderOutput = await recorder.stop();
        const audioBuffer = recorderOutput?.buffer[0];
        const { buffer } = await recorder.stop();
        const audio = exportBuffer(audioBuffer);
        const blobURL = URL.createObjectURL(audio)
        // console.log(audio,"<- audio");
        //  Audio process with gpt audio to text conversion
        //this.checkAudio(recorderOutput.blob);

        let formData = new FormData();
        formData.append('audio', recorderOutput.blob, 'audio.wav');
        formData.append('login_uuid',this.state.sessionUID);
        if(this.props.category=='Legacy')
        {
            formData.append('type',  'legacy');
            this.setState({ boatID: "RMEHNYXF57" });
        }
        else if(this.props.category=='Dating')
        {
            formData.append('type',  'Dating');
            this.setState({ boatID: "QPSJB0MH2O" });
            this.setState({ botAliasId: "YJ0MHSCRFG" });

            
        }
        else
        {
            formData.append('type',  'Squirrel');
            this.setState({ boatID: "ZZQU09YS8F" });
        }
        
       console.log('this.state.boatID',this.state.boatID);
        const command = new RecognizeUtteranceCommand({
            "botAliasId": this.state.botAliasId,
            //"botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
            "botId":this.state.boatID,
            "localeId": "en_US",
            "inputStream": audio,
            "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
            "sessionId": "043758711856891",
            "responseContentType": "text/plain;charset=utf-8"
        });
        
        this.setState({
            recording: 2
        });
       
         await this.props.convertAudioToText(formData,this.props.auth.loginUserToken,async(res)=>{
            this.gptRes = res
          this.setState({gptResp:res})
        })
        await this.props.whisperAudioToText(formData,this.props.auth.loginUserToken,async(res)=>{
            this.props.textFromWhisper(res)
        //     this.gptRes = res
        //   this.setState({gptResp:res})
        })
        
        const recursiveLexCall = async () => {
            try {
              // Instead of calling the Lex API, we simulate a successful response:
              // Here we define dummy data for interpretations and inputTranscript.
              const dummyInterpretations = [
                { 
                  intent: "DummyIntent", 
                  slots: { slotName: "dummyValue" }, 
                  message: "This is a dummy response." 
                }
              ];
              const dummyInputTranscript = "This is a dummy input transcript.";
              // Directly pass our dummy data to the processDataWitWhisper function
              this.processDataWitWhisper(dummyInputTranscript, dummyInterpretations,blobURL,audio);
          
              // Reset chat_gpt_call counter
              this.setState({ chat_gpt_call: 0 });
          
              // UI updates (assuming these elements exist and must remain):
              $('.playerIcon').removeClass('fa-play');
              $('.playerIcon').addClass('fa-pause');
              $(".play_pausebutton").show();
              $('#topicPopup').hide();
          
            } catch (err) {
              // In case of error, increment and try again
              this.setState({ chat_gpt_call: this.state.chat_gpt_call + 1 });
              recursiveLexCall();
            }
          };

        const recursiveLexCall_main = async () =>{
            try{
                const thirdApiResponse =await this.props.client.send(command);
                // const [audioToTextResponse, thirdApiResponse] = await Promise.all([audioToTextPromise, thirdApiPromise]);
                if(thirdApiResponse.interpretations && thirdApiResponse.interpretations.length > 0 ){
                    var interpretations = JSON.parse(this.decompressGzip(thirdApiResponse.interpretations));
                    var inputTranscript = this.decompressGzip(thirdApiResponse.inputTranscript);
                    this.processDataWitWhisper(inputTranscript,interpretations)
                    this.setState({chat_gpt_call : 0})
                    $('.playerIcon').removeClass('fa-play');
                    $('.playerIcon').addClass('fa-pause');
                    $(".play_pausebutton").show();
            
                    $('#topicPopup').hide();
                }
               
            }catch(err){
                this.setState({chat_gpt_call : this.state.chat_gpt_call + 1})
                recursiveLexCall()
            }
         }
        recursiveLexCall()
    }

    async processDataWitWhisper(inputTranscript,interpretations,audioToTextResponse,audio){
       
        const obj = {
            event: this,
            ispassed: true,
            key: inputTranscript,
            interpretations: interpretations,
            // chatgpt_intent:audioToTextResponse.data.intents,
            login_uuid:this.state.sessionUID,
            audio:audio,
        }
        this.setState({
            recording: 0
        });
        this.props.getTextAudio(obj);
    }

    decompressGzip(response) {
        // Decode base64 (convert ascii to binary)
        var strData = atob(response);

        // Convert binary string to character-number array
        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });

        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var data = pako.inflate(binData);

        // Convert gunzipped byteArray back to ascii string:
        var strData = String.fromCharCode.apply(null, new Uint16Array(data));

        // Output to console
        return strData;
    }

    render() {
        const { recording, stream } = this.state;
        // Don't show record button if their browser doesn't support it.
        // if (!stream) {
        //     return null;
        // }

        return (
            <>
                {recording == 0 && <a className={this.state.recorderbtnActive?"click_talk_btn click_talk_btn_recording" : "click_talk_btn"} href="javascript:void(0)" onClick={this.startRecord}>
                    <i className="far fa-microphone"></i>
                    Click To Talk
                </a>}
                {recording == 1 && <a className={this.state.recorderbtnActive?"click_talk_btn click_talk_btn_recording bg_hover" : "click_talk_btn"} href="javascript:void(0)" onClick={this.stopRecord}>
                    <i className="far fa-microphone"></i>
                    Click To Stop
                </a>}
                {recording == 2 && <a className="click_talk_btn" href="javascript:void(0)">
                    <i className="far fa-microphone"></i>
                    Thinking...
                </a>}
                {recording == 3 && <a className="click_talk_btn" href="javascript:void(0)">
                    <i className="far fa-microphone"></i>
                    Responding...
                </a>}
            </>
        );
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
    getChatGptIntents:bindActionCreators(getChatGptIntents, dispatch),
    convertAudioToText: bindActionCreators(convertAudioToText, dispatch),
    whisperAudioToText: bindActionCreators(whisperAudioToText, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps) (RecorderTest);